
<template functional>
<svg width="16" height="16" viewBox="0 0 16 16" :class="[data.class, data.staticClass]" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="16" width="16" height="16" rx="1" transform="rotate(90 16 0)" fill="white"/>
  <rect x="16" y="13" width="2" height="16" rx="1" transform="rotate(90 16 13)" fill="currentColor"/>
  <rect x="16" y="7" width="2" height="16" rx="1" transform="rotate(90 16 7)" fill="currentColor"/>
  <rect x="16" y="1" width="2" height="16" rx="1" transform="rotate(90 16 1)" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconBurger'
}
</script>

<style lang="scss">
</style>
